<template>
	<div>
		<a-form class="mb-4" :form="form" @submit="handleSubmit">
			<div class="row">
				<div class="col-md-4">
					<a-form-item label="¿Es proveedor extranjero?">
						<a-select v-decorator="['foreign_supplier']">
							<a-select-option value="true"> Sí </a-select-option>
							<a-select-option value="false"> No </a-select-option>
						</a-select>
					</a-form-item>
				</div>
				<div class="col-md-8">
					<a-form-item label="Nombre comercial">
						<a-input autocomplete="off" v-decorator="['tradename', { rules: [{ required: true, message: 'Ingresa nombre del proveedor' }] }]" :disabled="!isEditable" />
					</a-form-item>
				</div>
				<div class="col-md-12" v-if="form.getFieldValue('foreign_supplier') == 'false'">
					<a-form-item label="Régimen fiscal">
						<a-select
							showSearch
							option-filter-prop="children"
							:filter-option="filterOption"
							v-decorator="[
								'tax_regime',
								{
									rules: [
										{
											required: true,
											message: 'Campo requerido.',
										},
									],
								},
							]"
						>
							<a-select-option v-for="regime in taxRegimes" :key="regime.id" :value="regime.id"> {{ regime.id }} - {{ regime.description }} </a-select-option>
						</a-select>
					</a-form-item>
				</div>
				<div class="col-md-4" v-if="form.getFieldValue('foreign_supplier') == 'false'">
					<a-form-item label="Razón Social">
						<a-input autocomplete="off" v-decorator="['business_name', { rules: [{ required: true, message: 'Campo requerido' }] }]" />
					</a-form-item>
				</div>
				<div class="col-md-4" v-if="form.getFieldValue('foreign_supplier') == 'false'">
					<a-form-item label="RFC">
						<a-input class="text-uppercase" v-decorator="['rfc', { rules: [{ required: true, message: 'Campo requerido' }] }]" autocomplete="off" />
					</a-form-item>
				</div>
				<div class="col-md-4">
					<a-form-item label="Email">
						<a-input
							type="email"
							autocomplete="off"
							v-decorator="[
								'email',
								{
									rules: [
										{ required: true, message: 'Campo requerido' },
										{ email: true, message: 'Ingresa email válido' },
									],
								},
							]"
							:disabled="!isEditable"
						/>
					</a-form-item>
				</div>

				<div class="col-md-8">
					<a-form-item label="Google Maps">
						<a-input autocomplete="off" v-decorator="['map_link']" :disabled="!isEditable" />
					</a-form-item>
				</div>
				<div class="col-md-4">
					<a-form-item label="Teléfono">
						<a-input
							v-mask="'##########'"
							autocomplete="off"
							v-decorator="['phone_number', { rules: [{ required: true, message: 'Campo requerido' }, { message: 'Ingresa número telefónico' }] }]"
							:disabled="!isEditable"
						/>
					</a-form-item>
				</div>
				<div class="col-md-8">
					<a-form-item label="Contacto">
						<a-input v-decorator="['contact_name']" autocomplete="off" />
					</a-form-item>
				</div>
				<div class="col-md-4 pt10" v-if="!isEmbebed">
					<div>
						Constancia de Situación Fiscal:
						<a-upload name="file" :multiple="false" :remove="handleRemove" :before-upload="beforeUpload" accept="application/pdf">
							<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
						</a-upload>
						<div v-if="actualSupplier.csfFile" class="pt10">
							<a-button @click="getSecureURL(actualSupplier.csfFile.id, 'show')"> <a-icon type="file" /> Visualizar Constancia </a-button>
						</div>
					</div>
				</div>
			</div>
			<a-button ref="formButton" v-show="false" htmlType="submit">Guardar</a-button>
		</a-form>
		<a-form class="mb-4" :form="formPayment" @submit="handleSubmitPayment">
			<div class="row">
				<div class="col-md-12">
					<hr class="hrText" data-content="Método de pago" />
				</div>
				<div :class="hasPayments ? 'col-md-6' : 'col-md-12'" v-if="isEditable">
					<div class="row">
						<div :class="formPayment.getFieldValue('payment_method') == '2' ? 'col-md-6' : 'col-md-4'">
							<a-form-item label="Método de pago">
								<a-select v-decorator="['payment_method', { rules: [{ required: true, message: 'Campo requerido' }] }]" @change="onChangePaymentMethod">
									<a-select-option value="1">Efectivo</a-select-option>
									<a-select-option value="2">Digital</a-select-option>
								</a-select>
							</a-form-item>
						</div>
						<div :class="formPayment.getFieldValue('payment_method') == '2' ? 'col-md-6' : 'col-md-4'">
							<a-form-item label="Institución bancaria">
								<a-select
									show-search
									option-filter-prop="children"
									:filter-option="filterOption"
									:allowClear="true"
									v-decorator="['banking_institution', { rules: [{ required: true, message: 'Campo requerido' }] }]"
								>
									<a-select-option v-for="element in localBankingInstitutionsList" :key="element.id" :value="element.id">{{ element.bank_name }} </a-select-option>
								</a-select>
							</a-form-item>
							<small @click="getBankingInstitutions" class="pointer">Recarga</small>
						</div>
						<div
							:class="formPayment.getFieldValue('payment_method') == '2' ? 'col-md-6' : 'col-md-4'"
							v-if="formPayment.getFieldValue('banking_institution') != -1 && formPayment.getFieldValue('banking_institution') != 11"
						>
							<a-form-item label="Número de cuenta / tarjeta">
								<a-input v-mask="'################'" v-decorator="['account_number']" autocomplete="off" />
							</a-form-item>
						</div>
						<div
							:class="formPayment.getFieldValue('payment_method') == '2' ? 'col-md-6' : 'col-md-4'"
							v-if="formPayment.getFieldValue('payment_method') == '2' && formPayment.getFieldValue('banking_institution') != -1 && formPayment.getFieldValue('banking_institution') != 11"
						>
							<a-form-item label="CLABE interbancaria">
								<a-input v-mask="'##################'" v-decorator="['clabe_number']" autocomplete="off" />
							</a-form-item>
						</div>
						<div class="col-md-12 pt10 text-right">
							<a-button icon="plus" type="primary" htmlType="submit" ref="btnPayment" v-show="showReturnButton">Añadir</a-button>
						</div>
					</div>
				</div>
				<div :class="isEditable ? 'col-md-6' : 'col-md-6 offset-3'" v-if="hasPayments">
					<a-collapse accordion>
						<a-collapse-panel
							v-for="(element, index) in payments"
							:key="index"
							:header="`${element.payment_method == '1' ? 'Efectivo' : 'Digital'}${element.bank == -1 ? '' : ` --> ${element.bankName}`}`"
						>
							<table class="table table-bordered table-condensed table-striped m0">
								<tbody>
									<tr>
										<td width="50%"><b>Institución bancaria</b></td>
										<td>{{ element.bankName }}</td>
									</tr>
									<tr>
										<td width="50%"><b>Número de cuenta / tarjeta</b></td>
										<td>{{ element.account_number | replaceIfLogicalFalse('- No establecido -') }}</td>
									</tr>
									<tr v-if="element.payment_method == '2'">
										<td width="50%"><b>CLABE interbancaria</b></td>
										<td>{{ element.clabe_number | replaceIfLogicalFalse('- No establecido -') }}</td>
									</tr>
								</tbody>
							</table>
							<div class="row">
								<div class="col-md-12 text-right" style="padding: 10px 25px" v-if="isEditable">
									<a-button type="danger" size="small" icon="delete" @click="onDeleteElement(index)" />
									<a-button class="btn btn-info ml7" size="small" icon="edit" @click="onEditElement(index)" />
								</div>
							</div>
							<div class="row"></div>
						</a-collapse-panel>
					</a-collapse>
				</div>
			</div>
		</a-form>
		<div class="row pt20" v-if="isEditable">
			<div class="col-md-12">
				<hr class="hrText" />
			</div>
			<div class="col-md-4 text-left">
				<a-button class="btn btn-warning" icon="arrow-left" @click="onCancel" v-if="showReturnButton">Regresar</a-button>
			</div>
			<div class="col-md-8 text-right">
				<a-button class="ml5 btn btn-success" icon="save" @click="onValidate">Guardar</a-button>
			</div>
		</div>
		<a-modal width="65%" :visible="modalFileVisible" :footer="null" title="Constancia de Situación Fiscal" :closable="true" @cancel="handleCancelModalFile">
			<div class="row">
				<div class="col-12 text-center">
					<pdf v-if="hasPDf" :src="fileURL"></pdf>
					<img :src="fileURL" class="img-fluid" v-else />
				</div>
				<div class="col-12 text-right">
					<hr class="hrDotted" />
					<a class="ml5 btn btn-primary" :href="fileURL" target="new">Descargar comprobante</a>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import Swal from 'sweetalert2'
import utilities from '@/services/utilities'
import { mask } from 'vue-the-mask'
import { taxRegimes } from '@/constants'
import pdf from 'vue-pdf'

export default {
	name: 'supplierDetail',
	directives: {
		mask,
	},
	components: {
		pdf,
	},
	props: {
		isEditable: {
			type: Boolean,
			default: true,
		},
		visible: {
			type: Boolean,
			default: false,
		},
		supplierId: {
			type: Number,
			default: 0,
		},
		showReturnButton: {
			type: Boolean,
			default: true,
		},
		isEmbebed: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			form: this.$form.createForm(this),
			formPayment: this.$form.createForm(this),
			payments: [],
			modal: {
				visible: false,
				title: '',
				payload: {},
			},
			hasReferenceLink: false,
			notApply: false,
			localBankingInstitutionsList: [],
			indexEdit: null,
			taxRegimes,
			fileList: [],
		}
	},
	computed: {
		...mapGetters('bankingInstitutions', ['bankingInstitutionsList']),
		...mapGetters('suppliers', ['actualSupplier', 'modalFileVisible', 'fileURL']),
		hasPayments() {
			return !!this.payments.length
		},
		hasPDf() {
			return this.fileURL.toLowerCase().split('.pdf').length == 2 ? true : false
		},
	},
	beforeMount() {
		if (!this.bankingInstitutionsList.length) {
			this.getBankingInstitutions()
		}
	},
	mounted() {
		this.localBankingInstitutionsList = _.cloneDeep(this.bankingInstitutionsList)
		if (this.$route.params.id != 'new') {
			// console.log(this.actualSupplier)

			this.form.setFieldsValue({
				foreign_supplier: utilities.objectValidate(this.actualSupplier, 'foreign_supplier', false) ? 'true' : 'false',
			})

			setTimeout(() => {
				this.form.setFieldsValue({
					tradename: utilities.objectValidate(this.actualSupplier, 'tradename', ''),
					business_name: utilities.objectValidate(this.actualSupplier, 'business_name', ''),
					tax_regime: utilities.objectValidate(this.actualSupplier, 'tax_regime', false) ? Number(this.actualSupplier.tax_regime) : '',
					rfc: utilities.objectValidate(this.actualSupplier, 'rfc', ''),
					email: utilities.objectValidate(this.actualSupplier, 'email', ''),
					foreign_supplier: utilities.objectValidate(this.actualSupplier, 'foreign_supplier', false) ? 'true' : 'false',
					map_link: utilities.objectValidate(this.actualSupplier, 'map_link', ''),
					phone_number: utilities.objectValidate(this.actualSupplier, 'phone_number', ''),
					contact_name: utilities.objectValidate(this.actualSupplier, 'contact_name', ''),
				})

				let payments = []
				if (utilities.objectValidate(this.actualSupplier, 'payment_methods', false)) {
					this.actualSupplier.payment_methods.forEach((e, i) => {
						payments.push({
							payment_method: utilities.objectValidate(e, 'payment_method', ''),
							banking_institution: utilities.objectValidate(e, 'banking_institution', ''),
							account_number: utilities.objectValidate(e, 'account_number', ''),
							clabe_number: utilities.objectValidate(e, 'clabe_number', ''),
							bankName: e.banking_institution > 0 ? this.localBankingInstitutionsList.find((b) => b.id == e.banking_institution).bank_name : '- No aplica -',
						})
					})
				}
				this.payments = _.cloneDeep(payments)
			}, 100)
		}
	},
	methods: {
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields(async (err, values) => {
				if (!err) {
					let payload = {
						...values,
						foreign_supplier: values.foreign_supplier == 'true' ? 1 : 0,
						payment_methods: _.cloneDeep(this.payments),
						rfc: values.foreign_supplier == 'false' ? values.rfc.toUpperCase() : '',
					}

					if (this.isEmbebed) {
						if (!this.hasPayments) {
							this.$refs.btnPayment.$el.click()
						}
						setTimeout(() => {
							if (!this.payments.length) {
								return false
							}
							payload.bank_information = _.cloneDeep(this.payments)
							this.$store.dispatch('suppliers/CREATE', { payload, redirect: false }).then((response) => {
								this.cleanData()
								this.$emit('closeModal', response)
							})
						}, 100)
					} else {
						if (!this.payments.length) {
							Swal.fire({
								title: 'Proveedores',
								text: 'Debes ingresar al menos un método de pago para el registro',
								icon: 'warning',
								confirmButtonText: 'Ok',
							})
							return false
						}

						const { fileList } = this
						var formData = undefined

						if (fileList.length) {
							formData = new FormData()
							formData.append('file', fileList[0])
							formData.append('fileType', 'file')
						}

						if (this.$route.params.id == 'new') {
							this.$store.dispatch('suppliers/CREATE', { payload, redirect: true, formData })
						} else {
							payload.id = this.$route.params.id
							this.$store.dispatch('suppliers/UPDATE', { payload, formData })
						}
					}
				}
			})
		},
		handleSubmitPayment(e) {
			e.preventDefault()
			this.formPayment.validateFields((err, values) => {
				if (!err) {
					let bankID = Number(values.banking_institution)
					values = {
						...values,
						bankName: bankID > 0 ? this.localBankingInstitutionsList.find((e) => e.id == bankID).bank_name : '- No aplica -',
					}

					if (values.account_number == '' && values.clabe_number == '') {
						Swal.fire({
							title: 'Atención',
							text: 'Debes ingresar número de cuenta o CLABE interbancaria',
							icon: 'warning',
							showCancelButton: false,
						})
						return false
					}

					if (this.indexEdit != null) {
						this.payments[this.indexEdit] = _.cloneDeep(values)
						this.indexEdit = null
					} else {
						this.payments.push(_.cloneDeep(values))
					}
					//
					this.formPayment.resetFields()
					this.onChangePaymentMethod(2)
				}
			})
		},
		onCancel() {
			this.$router.replace('/almacen')
		},
		onEditElement(index) {
			this.indexEdit = index
			this.formPayment.setFieldsValue({
				payment_method: utilities.objectValidate(this.payments[index], 'payment_method', ''),
				banking_institution: utilities.objectValidate(this.payments[index], 'banking_institution', ''),
				account_number: utilities.objectValidate(this.payments[index], 'account_number', ''),
			})

			setTimeout(() => {
				this.formPayment.setFieldsValue({
					clabe_number: utilities.objectValidate(this.payments[index], 'clabe_number', ''),
				})
			})
		},
		onDeleteElement(index) {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas eliminar el método de pago?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					let elements = _.cloneDeep(this.payments)
					let newElements = []
					elements.forEach((element, elementIndex) => {
						if (index != elementIndex) {
							newElements.push({
								...element,
							})
						}
					})
					this.payments = _.cloneDeep(newElements)
				}
			})
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		onLinkPress() {
			let string = this.form.getFieldValue('reference')
			if (utilities.isValidURL(string)) {
				window.open(string)
			} else {
				Swal.fire({
					title: 'Proveedores',
					html: 'El link parece no tener una estructura correcta',
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
			}
		},
		onValidate() {
			this.$refs.formButton.$el.click()
		},
		onChangePaymentMethod(value) {
			if (value == 1) {
				this.localBankingInstitutionsList.unshift({
					id: -1,
					bank_name: '- No aplica -',
					short_name: '- No aplica -',
				})
			} else {
				this.formPayment.setFieldsValue({ bank: '' })
				this.localBankingInstitutionsList.splice(0, 1)
			}
		},
		cleanData() {
			this.form.resetFields()
			this.formPayment.resetFields()
			this.payments = []
		},
		getBankingInstitutions() {
			this.$store.dispatch('bankingInstitutions/GET')
		},
		handleRemove() {
			this.fileList = []
		},
		beforeUpload(file) {
			this.fileList = [file]
			return false
		},
		getSecureURL(id, action) {
			this.$store.dispatch('suppliers/GET_FILE_URL', { id, action })
		},
		handleCancelModalFile() {
			//
			this.$store.commit('suppliers/SET_STATE', {
				modalFileVisible: false,
			})
		},
	},
	watch: {
		bankingInstitutionsList(newData) {
			if (newData) {
				this.localBankingInstitutionsList = _.cloneDeep(this.bankingInstitutionsList)
			}
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
<style lang="scss" scoped>
.table td,
.table th {
	padding: 0.25rem 0.75rem;
	vertical-align: top;
	border-top: 1px solid #dee2e6;
}
</style>